import React from "react";
import "./Projects.css";
// icons
import { FaReact, FaHardHat, FaVuejs } from "react-icons/fa";
import {
  SiBlockchaindotcom,
  SiHtml5,
  SiMaterialui,
  SiChai,
  SiEthereum,
  SiExpress,
  SiSocketdotio,
  SiTailwindcss,
  SiReactrouter,
  SiJavascript,
  SiMongodb,
  SiNodedotjs,
  SiFastapi,
  SiRedux,
  SiVuetify,
  SiPwa
} from "react-icons/si";
import { DiCss3 } from "react-icons/di";
import { VscJson } from "react-icons/vsc";
import { IoLogoJavascript } from "react-icons/io";
// images
import listifynoteapp from "../../assets/listify-note-app.png";
import chatApp from "../../assets/chat-app.png";
import foodDeliveryApp from "../../assets/food-delivery-app.png";
import videoPlatform from "../../assets/video-platform.png";
import ecomm from "../../assets/ecomm.png";
import photoApp from "../../assets/photo-app.png";

const projects = [
  {
    title: "Listify Note App",
    description:
      "Vue.js PWA note app with offline support, allowing you to add, edit, sort tasks, set deadlines, and track completion.",
    image: listifynoteapp, // actual image import
    technologies: [
      { name: "Vue.js", icon: <FaVuejs /> },
      { name: "JavaScript", icon: <SiJavascript /> },
      { name: "CSS3", icon: <DiCss3 /> },
      { name: "Vuetify", icon: <SiVuetify /> },
      { name: "PWA", icon: <SiPwa /> }
    ],
    liveLink: "https://listify-note-app.netlify.app",
    codeLink: "https://github.com/kmgaikwad97/vuetify-my-note-app"
  },
  {
    title: "Chat Application",
    description:
      "A real-time chat app built using Node.js, React, WebSocket, and WebRTC for instant messaging and video calls.",
    image: chatApp, // actual image import
    technologies: [
      { name: "React", icon: <FaReact /> },
      { name: "JavaScript", icon: <SiJavascript /> },
      { name: "CSS3", icon: <DiCss3 /> },
      { name: "Material UI", icon: <SiMaterialui /> },
      { name: "WebSocket", icon: <SiSocketdotio /> },
      { name: "WebRTC", icon: <FaHardHat /> }
    ],
    liveLink: "#", // add actual live link if available
    codeLink: "#", // add actual code link if available
  },
  {
    title: "Video App",
    description:
      "A scalable video streaming platform using React, Node.js, and Blob storage. Implements queuing and streaming for seamless video delivery, with adaptive bitrate and user recommendations.",
    image: videoPlatform, // actual image import
    technologies: [
      { name: "React", icon: <FaReact /> },
      { name: "JavaScript", icon: <SiJavascript /> },
      { name: "CSS3", icon: <DiCss3 /> },
      { name: "Material UI", icon: <SiMaterialui /> },
      { name: "Redux", icon: <SiRedux /> }
    ],
    liveLink: "#", // add actual live link if available
    codeLink: "#", // add actual code link if available
  },
  {
    title: "Ecommerce Application",
    description:
      "A dynamic e-commerce app built with React and Node.js, featuring product catalog, payment gateway, order tracking, and notifications for sales and order updates.",
    image: ecomm, // actual image import
    technologies: [
      { name: "React", icon: <FaReact /> },
      { name: "JavaScript", icon: <SiJavascript /> },
      { name: "CSS3", icon: <DiCss3 /> },
      { name: "Material UI", icon: <SiMaterialui /> }
    ],
    liveLink: "#", // add actual live link if available
    codeLink: "#", // add actual code link if available
  },
  {
    title: "Food Delivery App",
    description:
      "A React and Node.js-powered app with a strong backend, integrated with location APIs and Firebase notifications for real-time updates. It supports order tracking, restaurant browsing, and payment integration.",
    image: foodDeliveryApp, // actual image import
    technologies: [
      { name: "React", icon: <FaReact /> },
      { name: "Redux", icon: <SiRedux /> },
      { name: "JavaScript", icon: <SiJavascript /> },
      { name: "CSS3", icon: <DiCss3 /> },
      { name: "Material UI", icon: <SiMaterialui /> }
    ],
    liveLink: "#", // add actual live link if available
    codeLink: "#", // add actual code link if available
  },
  {
    title: "Photo App",
    description:
      "Innovative and self-created responsive photo App website, made on react, share photos with your friends.",
    image: photoApp, // actual image import
    technologies: [
      { name: "HTML5", icon: <SiHtml5 /> },
      { name: "CSS3", icon: <DiCss3 /> },
      { name: "JavaScript", icon: <SiJavascript /> },
      { name: "MongoDB", icon: <SiMongodb /> },
      { name: "Node.js", icon: <SiNodedotjs /> },
      { name: "Express", icon: <SiExpress /> }
    ],
    liveLink: "#", // add actual live link if available
    codeLink: "#", // add actual code link if available
  }
];

const ProjectCard = ({ project }) => {
  return (
    <div className="col-md-4 mb-4">
      <div className="card">
        <div className="project-video-container">
          <img src={project.image} className="card-img-top" alt={project.title} />
        </div>
        <div className="card-body">
          <h5 className="card-title">{project.title}</h5>
          <p className="card-text">{project.description}</p>
        </div>
        <div className="card-footer">
          <div className="tech-icons">
            {/* Iterate over the technologies array */}
            {project.technologies.map((tech, index) => (
              <span key={index} title={tech.name} style={{ marginRight: "10px" }}>
                {tech.icon}
              </span>
            ))}
          </div>
          <div className="card-links">
            <a href={project.liveLink} target="_blank"
              rel="noreferrer" className="btn-primary">
              <span type="button" className="onbt onbt">
                See this Live
              </span>
            </a>
            <a href={project.codeLink} className="btn-primary" target="_blank" rel="noreferrer">
              <span type="button" className="onbt">
                View Code
              </span>
            </a>
          </div>
        </div>

      </div>
    </div>
  )
}

export const Projects = () => {
  return (
    <>
      <div className="container">
        <h2 className="section__title">Projects</h2>
        <div className="row">
          {projects.map((project, index) => (
            <ProjectCard key={index} project={project} />
          ))}
        </div>
      </div>
    </>
  );
};
